/* .c-phone
-----------------------------------------------------------*/
$r: ".c-phone";

// block
//-----------------------------------------------------------
#{$r} {
  display: grid;
  grid-gap: $size * 2;
  place-items: center;
  grid-auto-flow: column;
  justify-content: start;
  width: 100%;
  padding: $size;
  height: $size * 6;
  border: 1px solid $gray;
  border-radius: $border-radius;
  background-color: $white;

  // element
  //---------------------------------------------------------
  &__country {
    display: grid;
    grid-gap: $size;
    place-items: center;
    grid-auto-flow: column;
    cursor: pointer;
  }

  &__flag {
    display: grid;
    font-size: 32px;
  }

  &__code {
    font-weight: 600;
  }

  &__arrow {
    display: grid;
    font-size: 8px;
  }

  &__delimiter {
    height: $size * 3;
    min-width: 1px;
    background-color: $gray;
  }

  &__input {
    font-size: 16px;
    font-weight: 600;
  }

  // modifier
  //---------------------------------------------------------
}

// influence
//-----------------------------------------------------------
