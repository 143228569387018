/* .l-row
-----------------------------------------------------------*/
$r: ".l-row";

// block
//-----------------------------------------------------------
#{$r} {
  margin-top: $size * 4;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: $size * 4;
  }

  // element
  //---------------------------------------------------------
  .icon {
    &--error {
      font-size: 48px;
    }

    &--check {
      font-size: 48px;
      width: calc(135.1em / 100);
    }

    &--envelope {
      font-size: 48px;
      width: calc(113em / 100);
    }
  }

  .c-barcode {
    background-color: $white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    padding: $size;
  }

  // modifier
  //---------------------------------------------------------
  &--mt-16 {
    margin-top: $size * 2;
  }

  &--mt-80 {
    margin-top: $size * 10;
  }

  &--flex {
    display: flex;
  }

  &--jc-sb {
    justify-content: space-between;
  }
}

// influence
//-----------------------------------------------------------
