@import "src/scss/b-vars";

.container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  height: 100%;

  svg {
    width: 80%;
    height: auto;
    color: $black;
  }
}