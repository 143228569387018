@use 'sass:math';
@import "src/scss/b-vars";
$size: 150px;

.container {
  display: flex;
  align-items: center;
  padding: math.div($size, 2);
  justify-content: center;

  overflow: hidden;
  position: relative;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: $size;
  width: $size;
  transform: scale(1);
  animation: pulse-green 2s infinite;
}

.green {
  background: $primary;
  box-shadow: 0 0 0 0 $primary;
  animation: pulse-green 2s infinite;
}

.logoBg {
  border-radius: 50%;
  background-color: $background;
  position: absolute;
  width: $size;
  height: $size;
}

.logo {
  color: $primary;
  position: absolute;
  width: $size;
  height: $size;
  
  svg {
    width: $size;
    height: $size;
  }
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $primary_70;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 math.div($size, 2) $primary_0;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $primary_0;
  }
}